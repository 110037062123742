import React, { Component } from "react";
import LoadingSpinner from "@/components/LoadingSpinner";
import Payment from "../../components/payment/Payment";
import axios from "@/axios";
import { applyVueInReact } from "vuereact-combined";

class InternetBillSubisu extends Component {
  LoadingSpinnerComp = applyVueInReact(LoadingSpinner);

  constructor() {
    super();
    this.state = {
      message: "",
      loading: true,
      username: "",
      reports: "",
      paymentOption: "",
      selectedInternetPackage: {},
      selectedOfferPackage: {},
      selectedTvPackage: {},
      selectedStbId: {},
      amount: 0,
    };
  }
  componentDidMount() {
    this.setState({ loading: false });
  }

  handleChange = (event) => {
    let { name, value } = event.target;
    if (name === "selectedStbId") {
      this.setState({ selectedTvPackage: {} });
    }
    if (name === "paymentOption") {
      this.setState({
        selectedInternetPackage: {},
        selectedOfferPackage: {},
        selectedStbId: {},
        selectedTvPackage: {},
      });
    }
    if (
      name === "selectedInternetPackage" ||
      name === "selectedOfferPackage" ||
      name === "selectedStbId" ||
      name === "selectedTvPackage"
    ) {
      value = value ? JSON.parse(value) : "";
    }
    this.setState({ [name]: value }, () => {
      //setting the amount after selecting the options.
      if (name === "paymentOption") {
        if (this.state.paymentOption === "Clear Due") {
          this.setState({ amount: this.state.reports.OutstandingAmount });
        }
      }
      if (name === "selectedOfferPackage") {
        this.setState({ amount: this.state.selectedOfferPackage.Amount });
      }
      if (name === "selectedInternetPackage") {
        this.setState({ amount: this.state.selectedInternetPackage.Amount });
      }
      if (name === "selectedTvPackage") {
        this.setState({ amount: this.state.selectedTvPackage.Amount });
      }
    });
  };
  CheckSubisuAccount = async (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    const data = await axios.post("/api/v2/services/subisu/getAccountDetail", {
      SubisuUserName: this.state.username,
    });
    this.setState({ loading: false, message: "" });
    if (data.data && data.data.response) {
      if (data.data.response.Code !== "000") {
        this.setState({ message: data.data.response.Message });
      } else {
        this.setState({ reports: data.data.response });
      }
    }
  };
  validationBeforePayment = () => {
    if (!this.state.paymentOption) {
      this.setState({ message: "Please select the payment option." });
      return false;
    }
    if (
      this.state.paymentOption === "Offer Payment" &&
      !Object.keys(this.state.selectedOfferPackage).length > 0
    ) {
      this.setState({ message: "Please select the Offer Package." });
      return false;
    }
    if (
      this.state.paymentOption === "Internet Payment" &&
      !Object.keys(this.state.selectedInternetPackage).length > 0
    ) {
      this.setState({ message: "Please select the Internet Package." });
      return false;
    }
    if (this.state.paymentOption === "TV Payment") {
      if (!Object.keys(this.state.selectedStbId).length > 0) {
        this.setState({ message: "Please select the StbId." });
        return false;
      }
      if (!Object.keys(this.state.selectedTvPackage).length > 0) {
        this.setState({ message: "Please select the TV package." });
        return false;
      }
    }
    return true;
  };

  subisuPaymentPayload = () => {
    let payload = {};
    if (this.state.paymentOption === "Offer Payment") {
      payload = {
        OfferId: this.state.selectedOfferPackage.OfferId,
      };
    }
    if (this.state.paymentOption === "Internet Payment") {
      payload = {
        PackageName: this.state.selectedInternetPackage.PackageName,
      };
    }
    if (this.state.paymentOption === "TV Payment") {
      payload = {
        StbId: this.state.selectedStbId.STBId,
        PackageName: this.state.selectedTvPackage.PackageName,
      };
    }
    return {
      ...payload,
      Amount: this.state.amount,
      PhoneNumber: this.state.reports.MobileNo,
      CustomerName: this.state.username,
      PaymentOption: this.state.paymentOption,
    };
  };
  render() {
    return (
      <div className="container">
        <div className="wallet-form-box card">
          <div className="card-body">
            <h5 className="card-title"> Subisu Payment.</h5>
            <a href="/" className="btn btn-close">
              <i className="fa fa-times"></i>
            </a>
            {this.state.message && (
              <div className="form-group fade-animation">
                <div className="show-message">{this.state.message}</div>
              </div>
            )}
            <div className="row">
              <div className="col-md-7">
                {this.state.reports ? (
                  <div className="fade-animation">
                    <div className="mt-4">
                      <div className="font-weight-bold">UserId</div>
                      <div>{this.state.reports.UserId}</div>
                    </div>
                    <div className="mt-4">
                      <div className="font-weight-bold"> Customer Name</div>
                      <div>{this.state.reports.Name}</div>
                    </div>
                    <div className="mt-4">
                      <div className="font-weight-bold">
                        Customer Mobile Number
                      </div>
                      <div>{this.state.reports.MobileNo}</div>
                    </div>
                    <div className="mt-4">
                      <div className="font-weight-bold">Customer Address</div>
                      <div>{this.state.reports.Address}</div>
                    </div>

                    {this.state.reports.CurrentInternetPlan && (
                      <div className="mt-4">
                        <div className="font-weight-bold">
                          Current Internet Plan
                        </div>
                        <>{this.state.reports.CurrentInternetPlan}</>
                      </div>
                    )}
                    {this.state.reports.CurrentTvPlan &&
                      this.state.reports.CurrentTvPlan.length > 0 && (
                        <div className="mt-4">
                          <div className="font-weight-bold">
                            Current Tv Plan
                          </div>
                          {this.state.reports.CurrentTvPlan.map(
                            (plan, index) => {
                              const formatedDate = plan.ExpiryDate.substring(
                                0,
                                plan.ExpiryDate.indexOf("T")
                              );

                              return (
                                <div
                                  className="mt-2 pl-3 border-bottom"
                                  key={index}
                                >
                                  <p>
                                    <span className="font-weight-bold pr-1">
                                      Plan Name :
                                    </span>
                                    {plan.PlanName}
                                  </p>
                                  <p>
                                    <span className="font-weight-bold pr-1">
                                      StbId :
                                    </span>
                                    {plan.StbId}
                                  </p>
                                  <p>
                                    <span className="font-weight-bold pr-1">
                                      Expiry Date:
                                    </span>
                                    {formatedDate}
                                  </p>
                                </div>
                              );
                            }
                          )}
                        </div>
                      )}
                    <div className="mt-4">
                      <div className="font-weight-bold">Payment Options</div>
                      <div className="form-group">
                        <select
                          className="custom-select"
                          name="paymentOption"
                          onChange={this.handleChange}
                        >
                          <option value="">-- Select Payment Options --</option>
                          {this.state.reports.PaymentOptions.map(
                            (plan, index) => {
                              return (
                                <option key={index} value={plan}>
                                  {plan}
                                </option>
                              );
                            }
                          )}
                        </select>
                      </div>
                    </div>
                    {this.state.paymentOption === "Clear Due" ? (
                      <div className="mt-4">
                        <div className="font-weight-bold"> Amount</div>
                        <>Rs. {this.state.amount}</>
                      </div>
                    ) : this.state.paymentOption === "Internet Payment" ? (
                      <div className="mt-4">
                        <div className="font-weight-bold">Internet Package</div>
                        <select
                          key={`internet-${this.state.paymentOption}`}
                          className="custom-select"
                          name="selectedInternetPackage"
                          onChange={this.handleChange}
                        >
                          <option value="">
                            -- Select Internet Package --
                          </option>
                          {this.state.reports.InternetPackage.map((pkg) => (
                            <option
                              key={pkg.PackageName}
                              value={JSON.stringify(pkg)}
                            >
                              {pkg.PackageName}
                            </option>
                          ))}
                        </select>
                        {Object.keys(this.state.selectedInternetPackage)
                          .length > 0 && (
                          <div className="mt-4">
                            <div className="font-weight-bold">Amount</div>
                            <>Rs. {this.state.amount}</>
                          </div>
                        )}
                      </div>
                    ) : this.state.paymentOption === "Offer Payment" ? (
                      <>
                        <div className="mt-4">
                          <div className="font-weight-bold">Offer Package</div>
                          <select
                            key={`offer-${this.state.paymentOption}`}
                            className="custom-select"
                            name="selectedOfferPackage"
                            onChange={this.handleChange}
                          >
                            <option value="">-- Select Offer Package --</option>
                            {this.state.reports.OfferPackage.map((pkg) => (
                              <option
                                key={pkg.OfferId}
                                value={JSON.stringify(pkg)}
                              >
                                {pkg.OfferName}
                              </option>
                            ))}
                          </select>
                        </div>
                        {Object.keys(this.state.selectedOfferPackage).length >
                          0 && (
                          <>
                            <div className="mt-4 pl-3">
                              {this.state.selectedOfferPackage.PlanDetails.map(
                                (plan, index) => {
                                  return (
                                    <div
                                      className="mt-2 border-bottom"
                                      key={index}
                                    >
                                      <div className="font-weight-bold">
                                        Plan {index}
                                      </div>
                                      <p>Plan Type: {plan.PlanType}</p>
                                      <p>Service Media: {plan.ServiceMedia}</p>
                                      <p>
                                        Expiry Date: {plan.ExpiryDateString}
                                      </p>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                            <div className="mt-4">
                              <div className="font-weight-bold">Amount</div>
                              <>Rs. {this.state.amount}</>
                            </div>
                          </>
                        )}
                      </>
                    ) : this.state.paymentOption === "TV Payment" ? (
                      <>
                        <div className="mt-4">
                          <div className="font-weight-bold">StbId</div>
                          <select
                            key={`stbId-${this.state.paymentOption}`}
                            className="custom-select"
                            name="selectedStbId"
                            onChange={this.handleChange}
                          >
                            <option value="">-- Select StbId --</option>
                            {this.state.reports.TvPackage.map((pkg) => (
                              <option
                                key={pkg.STBId}
                                value={JSON.stringify(pkg)}
                              >
                                {pkg.STBId}
                              </option>
                            ))}
                          </select>
                        </div>
                        {Object.keys(this.state.selectedStbId).length > 0 && (
                          <div className="mt-4">
                            <div className="font-weight-bold">TV Package</div>
                            <select
                              key={this.state.selectedStbId.STBId}
                              className="custom-select"
                              name="selectedTvPackage"
                              onChange={this.handleChange}
                            >
                              <option value="">-- Select TV Package --</option>
                              {this.state.selectedStbId.Packages.map(
                                (pkg, index) => (
                                  <option
                                    key={`TvPackage-${index}`}
                                    value={JSON.stringify(pkg)}
                                  >
                                    {pkg.PackageName}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        )}
                        {Object.keys(this.state.selectedTvPackage).length >
                          0 && (
                          <div className="mt-4">
                            <div className="font-weight-bold">Amount</div>
                            <>Rs. {this.state.amount}</>
                          </div>
                        )}
                        <></>
                      </>
                    ) : (
                      <></>
                    )}
                    {/* PAYENT DIVISION */}
                    <Payment
                      paymentUri="api/v4/subisu/payment"
                      paymentPayload={this.subisuPaymentPayload()}
                      setMessage={(message) => {
                        this.setState({ message });
                      }}
                      validationBeforePayment={this.validationBeforePayment}
                      paymentPreviewDetail={{
                        "Customer Name": this.state.reports.Name,
                        "Subisu User Name": this.state.reports.UserId,
                        "Total Amount to Pay": "Rs. " + this.state.amount,
                      }}
                    />
                  </div>
                ) : (
                  <form onSubmit={this.CheckSubisuAccount}>
                    <div className="form-group mt-2">
                      <label>
                        Username <i className="fa fa-asterisk"></i>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Type Username and press enter."
                        value={this.state.username}
                        name="username"
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <button className="btn btn-primary mt-2" type="submit">
                      Search
                    </button>
                  </form>
                )}
              </div>
            </div>
            <this.LoadingSpinnerComp loading={this.state.loading} />
          </div>
        </div>
      </div>
    );
  }
}

export default InternetBillSubisu;
